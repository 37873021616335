.slider {
    max-width: 1110px;
    margin: 0 auto;
}

.slick-track {
    padding-top: 53px;
    padding-bottom: 53px;
    display: flex;
}

.slick-slide {
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.slick-slide:not(.slick-current) {
    filter: brightness(0.3);
}

.slick-slide.slick-current {
    transform: scale(1.5);
    position: relative;
    z-index: 1;
}

.slick-slide img {
    width: 100%;
}


.slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0px;
}

.slick-dots.slick-thumb li {
    list-style-type: none;
    margin: 0px 5px;
}

.slick-dots.slick-thumb li button {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #FFF;
    color: transparent;
    border: none;
    margin-left: auto;
    margin-right: auto;
}
  
.slick-dots.slick-thumb li.slick-active button {
    background: #00baff;
}

.slick-dots li button:before, .slick-dots li.slick-active button:before {
    color: transparent;
}

.arrow {
    position: absolute;
    color: #000000;
    font-size: 2.5em;
    font-weight: bold;
    z-index: 1;
    cursor: pointer;
    background: transparent;
    border: none;
}

.arrow img {
    width: 8vw;
}
  
.nextArrow, .prevArrow {
    top: 50%;
    transform: translate(0, -50%);
}
  
.prevArrow {
    left: 10%;
}

.nextArrow {
    right: 10%;
}

#cont {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

@media (max-width: 575px) {
    .slick-dots.slick-thumb li {
        margin: 0px 3px;
    }
    .slick-dots.slick-thumb li button {
        width: 16px;
        height: 16px;
    }
}

@media (max-width: 480px) {
    .slick-dots.slick-thumb li {
        margin: 1px;
    }
    .slick-dots.slick-thumb li button {
        width: 14px;
        height: 14px;
    }
}

@media (max-width: 320px) {
    .slick-dots.slick-thumb li button {
        width: 12px;
        height: 12px;

    }
    .slick-dots.slick-thumb li {
        margin: 0px;
    }
}