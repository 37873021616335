@media screen and (max-width: 991px) {
    .slick-list {
        padding: 45px 33% !important;
    }
}

.slick-track {
    margin-top: 30px;
}

@media screen and (max-width: 320px) {
    .slick-list {
        padding: 0px 33% !important;
    }
}
/* 

     @keyframes bounceAround {
  from {
    opacity: 0;
    transform: scale3d(0.8,0.8,0.5);
  }

  50% {
    opacity: 1;
  }
}

@keyframes slickcloned {
      from {
    transform: translate3d(0, -20%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }

}

.slick-center {
  animation-name: slick-center;
  transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
}


      .slick-center {
        animation: bounceAround 1s ease-in-out;
		animation-duration: 1s;
      }


      .slick-cloned {
          animation-name: slick-cloned;
    animation-duration: 0.5s;
}

.slick-cloned {
        animation: slickcloned 1s ease-in-out;
		
      }

      .ihWTKa {
          animation-name: slick-cloned;
    animation-duration: 0.5s;
}

.ihWTKa {
        animation: slickcloned 1s ease-in-out;
		
      }

 */
