.history-modal-header {
    background-color: #011436;
    border-radius: 30px;
    text-align: center;
    padding: 10px 0px 5px;
    margin-bottom: 20px;
}

.history-modal-header label {
    font-size: 24px;
}

.item {
    margin-bottom: 40px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: transparent !important;
}

@media (max-width: 991px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        inset: 200px 30% 40px !important;
        max-height: 500px !important;
    }
    .item {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        inset: 200px 25% 40px !important;
    }
}

@media (max-width: 575px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        max-height: 400px !important;
    }
    .item {
        margin-bottom: 15px;
    }
}